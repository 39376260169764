import markerSDK from "@marker.io/browser";
import { useEffectOnce } from "../../hooks/use-effect-once";
const MarkerIO = () => {
  let destination: string;
  if (process.env.NEXT_PUBLIC_MARKER_ENV === `development` && process.env.NODE_ENV === `production`) {
    destination = `645148184c9045f2b22f6886`;
  }
  useEffectOnce(() => {
    if (destination) {
      markerSDK.loadWidget({
        destination
      });
    }
  });
  return <></>;
};
export default MarkerIO;