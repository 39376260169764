import { ThemeColors } from "../types/theme-colors";

// sets the css variables on the root dom object
const setThemeCSS = ({
  primaryColor,
  accentColor
}: ThemeColors) => {
  const root = document.documentElement;

  // break out early if there is no document root
  if (typeof root === `undefined`) {
    console.warn(`Cannot set theme: there is not document root.`);
    return;
  }

  // ========= PRIMARIES =========
  root.style.setProperty(`--color-primary`, `var(--palette-primary-${primaryColor})`);
  root.style.setProperty(`--color-primary-dark`, `var(--palette-primary-${primaryColor}-dark)`);
  root.style.setProperty(`--color-primary-bright`, `var(--palette-primary-${primaryColor}-bright)`);
  root.style.setProperty(`--color-primary-screen`, `var(--palette-primary-${primaryColor}-screen)`);
  root.style.setProperty(`--color-primary-shadow`, `var(--palette-primary-${primaryColor}-shadow)`);

  // ========= ACCENTS =========
  root.style.setProperty(`--color-accent`, `var(--palette-accent-${accentColor})`);
  root.style.setProperty(`--color-accent-screen`, `var(--palette-accent-${accentColor}-screen)`);
  root.style.setProperty(`--color-accent-shadow`, `var(--palette-accent-${accentColor}-shadow)`);
};
export default setThemeCSS;