import { accentColors, primaryColors } from "@a101/components/design-system/types/theme-colors";
import { useSession } from "next-auth/client";
import useOrgTheme from "./hooks/use-theme";

/**
 * Loads the user's organization theme.
 */
const Theme = () => {
  const [session] = useSession();
  useOrgTheme({
    primaryColor: session?.organization?.primaryColor ? primaryColors[session?.organization?.primaryColor] : undefined,
    accentColor: session?.organization?.accentColor ? accentColors[session?.organization?.accentColor] : undefined
  });
  return <></>;
};
export default Theme;