export const primaryColors = ({
  VIOLET: `violet`,
  TEAL: `teal`,
  GREEN: `green`,
  RED: `red`,
  FUCHSIA: `fuchsia`,
  GOLD: `gold`,
  BLUE: `blue`,
  SLATEGRAY: `slategray`,
  STEELBLUE: `steelblue`
} as const);
export type PrimaryColor = (typeof primaryColors)[keyof typeof primaryColors];
export const accentColors = ({
  SALMON: `salmon`,
  GRAY: `gray`,
  VIOLET: `violet`,
  BLUE: `blue`,
  TURQUOISE: `turquoise`,
  GREEN: `green`,
  GOLD: `gold`,
  YELLOW: `yellow`,
  ORANGE: `orange`,
  PINK: `pink`
} as const);
export type AccentColor = (typeof accentColors)[keyof typeof accentColors];
export type ThemeColors = {
  primaryColor: PrimaryColor | undefined;
  accentColor: AccentColor | undefined;
};