import Head from "next/head";
interface SEOValues {
  title?: string;
  description?: string;
  author?: string;
  site?: string;
}
const SEO = ({
  title,
  description,
  author,
  site
}: SEOValues): JSX.Element => {
  return <Head>
			<meta charSet="utf-8" />

			<title>{title}</title>
			<meta name="description" content={description} />

			{/* <meta name="viewport" content="width=device-width, initial-scale=1" key="viewport" /> */}

			{/* FAVICONS / ICONS */}
			<link rel="shortcut icon" href="/favicons/favicon.ico" />
			<link rel="manifest" href="/favicons/site.webmanifest" />
			<link rel="apple-touch-icon" sizes="180x180" href="/favicons/apple-touch-icon.png" />
			<link rel="icon" type="image/png" sizes="32x32" href="/favicons/favicon-32x32.png" />
			<link rel="icon" type="image/png" sizes="16x16" href="/favicons/favicon-16x16.png" />
			<link rel="mask-icon" href="/favicons/safari-pinned-tab.svg" color="#2e2270" />
			<meta name="apple-mobile-web-app-title" content="A101+" />
			<meta name="application-name" content="A101+" />
			<meta name="msapplication-TileColor" content="#fefce5" />
			<meta name="msapplication-config" content="/favicons/browserconfig.xml" />
			<meta name="theme-color" content="#fefce5" />

			{/* OPENGRAPH */}
			<meta property="og:title" content={title} />
			<meta property="og:description" content={description} />
			<meta property="og:type" content="website" />
			<meta property="og:image" content={`${site}/images/og.jpg`} />
			<meta name="twitter:card" content="summary_large_image" />
			<meta name="twitter:creator" content={author || ``} />
			<meta name="twitter:title" content={title} />
			<meta name="twitter:description" content={description} />

			{/* ACTUAL SIZE */}
			<meta name="made-by" content="Actual Size <https://actualsize.com>" />
		</Head>;
};
export default SEO;