/* eslint-disable @typescript-eslint/quotes */
import { useEffect, useState } from "react";
import setThemeCSS from "@a101/components/design-system/utils/set-theme-css";
import { ThemeColors } from "@a101/components/design-system/types/theme-colors";
import { AppStorage } from "../app-storage";

/**
 * Use an organization's theme colors to color the entire site.
 *
 * 1. If colors are passed as props, use those, then set localStorage with that theme.
 * 2. If no colors are passed as props, check localStorage for a theme, and apply it if found.
 * 3. If no colors are passed as props, and no theme is found in localStorage, use the default theme.
 */
const useOrgTheme = (themeColors?: ThemeColors) => {
  const [theme, setTheme] = useState<ThemeColors>({
    primaryColor: themeColors?.primaryColor,
    accentColor: themeColors?.accentColor
  });

  // when the page loads
  useEffect(() => {
    if (!themeColors?.primaryColor && !themeColors?.accentColor) {
      const appStorage = new AppStorage();
      const localTheme = appStorage.getItem("theme");
      if (localTheme) {
        setTheme(JSON.parse(localTheme));
        return;
      }
      setThemeCSS({
        primaryColor: `blue`,
        accentColor: `salmon`
      });
    }
  }, []);

  // if the theme is passed in after the page loads, set it to state
  useEffect(() => {
    if (themeColors && themeColors?.primaryColor !== theme?.primaryColor && themeColors?.accentColor !== theme?.accentColor) {
      setTheme(themeColors);
    }
  }, [themeColors]);

  // whenever the theme state changes, apply it to the site and save it to localStorage
  useEffect(() => {
    if (theme?.primaryColor && theme?.accentColor) {
      setThemeCSS({
        primaryColor: theme.primaryColor,
        accentColor: theme.accentColor
      });
      const appStorage = new AppStorage();
      appStorage.setItem("theme", JSON.stringify({
        primaryColor: theme.primaryColor,
        accentColor: theme.accentColor
      }));
    }
  }, [theme]);
  return theme;
};
export default useOrgTheme;