import type { Session } from "next-auth";
import { setUser, setTag, setContext } from "@sentry/nextjs";
export function attachSentryContext(session: Session) {
  // set the user id and email to the session
  setUser({
    id: session?.user?.id,
    email: session?.user?.email ?? ""
  });

  // set the user role as a tag to the session
  setTag(`role`, session?.user?.role);

  // set the user name, role, and role elevations as context on the session
  setContext(`userInfo`, {
    name: session?.user?.name,
    role: session?.user?.role,
    roleElevations: session?.user.roleElevations
  });

  // if the user has an organization
  if (session?.organization) {
    // set the org id as a tag on the session
    setTag(`org.id`, session?.organization?.id);

    // set the organization id and name as context on the session
    setContext(`organization`, {
      id: session?.organization?.id,
      name: session?.organization?.name
    });
  }
}