/**
 * Class to protect against localStorage security errors
 * https://hackernoon.com/why-localstorage-still-crashes-your-website-in-2023
 * https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API
 */
export class AppStorage {
  getItem(key: string) {
    if (this.storageAvailable()) {
      return window.localStorage?.getItem(key);
    }
    return;
  }
  setItem(key: string, value: string) {
    if (this.storageAvailable()) {
      window.localStorage?.setItem(key, value);
    }
  }
  storageAvailable() {
    let storage;
    try {
      storage = window.localStorage;
      const x = "__storage_test__";
      storage.setItem(x, x);
      storage.removeItem(x);
      return true;
    } catch (error) {
      return error instanceof DOMException && (
      // everything except Firefox
      error.code === 22 ||
      // Firefox
      error.code === 1014 ||
      // test name field too, because code might not be present
      // everything except Firefox
      error.name === "QuotaExceededError" ||
      // Firefox
      error.name === "NS_ERROR_DOM_QUOTA_REACHED") &&
      // acknowledge QuotaExceededError only if there's something already stored
      storage && storage.length > 0;
    }
  }
}